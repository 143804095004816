import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  Button,
  Box,
  Heading,
  Container,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from "@chakra-ui/core"
import { HiPhone } from "react-icons/hi"
import { FaFacebook } from "react-icons/fa"

import Layout from "components/layout"
import SEO from "components/seo"

const BlogIndex = ({ data }) => {
  const contactData = data.site.siteMetadata.contact
  return (
    <Layout>
      <SEO title="รับล้างฮูด ซ่อมเครื่องดูดควัน" />
      <VStack
        p="4"
        bg="blue.100"
        d="flex"
        flexDir="column"
        justifyContent="center"
        textAlign="center"
        spacing="4"
      >
        {/* <Box w={["282px", "400px"]}>
          <Image fluid={logo} alt="Prohood logo" objectPosition="center" />
        </Box> */}
        <Box>
          <Heading as="h1" textStyle="h3">
            ล้างฮูด
          </Heading>
          <Heading as="h1" textStyle="h3">
            ซ่อมระบบดูดควัน
          </Heading>
          <Heading as="h1" textStyle="h3">
            มอเตอร์มีปัญหา
          </Heading>
        </Box>
        <AnchorLink to="/#contact">
          <Button size="lg" rounded="full" colorScheme="blue">
            โทร {contactData.phone} ({contactData.name})
          </Button>
        </AnchorLink>
        <Heading as="p" textStyle="h3" color="red.600">
          ให้คำปรึกษาฟรี 24 ชม. ทุกวัน
        </Heading>
      </VStack>
      <Container centerContent my="8">
        <VStack spacing="4">
          <Heading
            as="h2"
            textStyle="h3"
            borderBottom="2px"
            borderBottomColor="red.600"
          >
            ปัญหาที่พบบ่อย
          </Heading>
          <Box w="full">
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text textStyle="h4">ฮูดไม่ดูด ลมไม่แรง</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  เครื่องดูดควันมีแรงดูดเบากว่าปกติ หรือฮูดไม่มีแรงดูด
                  เกิดได้จากมอเตอร์ฮูดหรือ blower ฮูดประสิทธิภาพ ลดลง
                  เสื่อมตามอายุและการใช้งาน จำเป็นต้องเปลี่ยน
                  <br />
                  <br />
                  เรามีบริการเปลี่ยนและซ่อมแซมมอเตอร์และพัดลม blower
                  สำหรับระบบดูดควัน ที่รวดเร็ว ให้ธุรกิจของคุณ
                  เดินต่อได้ทันทีไมติดขัด
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text textStyle="h4">ปล่องดูดควันสกปรก</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  การทำความสะอาดเพียงฝาครอบปล่องดูดควัน อาจไม่เพียงพอ
                  เพราะภายในยังสกปรกอยู่ เข้าถึงยาก
                  มีคราบน้ำมันและฝุ่นสะสมในท่อระบายจำนวนมาก ต้องทำความสะอาด
                  กำจัดคราบไขมันและสิ่งสกปรก จะทำให้ลมดูดแรงขึ้นและปลอดภัย
                  <br />
                  <br />
                  เราเป็นผู้เชี่ยวชาญในการล้างระบบ hood, exhaust pipe
                  ช่วยยืดอายุการใช้งาน และลดความเสี่ยงเกิดเพลิงไหม้
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text textStyle="h4">มอเตอร์เสียงดัง เปิดไม่ติด</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  เมื่อเปิดฮูดแล้วมีเสียงดังผิดปกติ ลมดูดไม่แรง หรือ เปิดไม่ติด
                  สาเหตุมีได้หลายประการ เช่นสายไฟขาด วงจรเสียหาย
                  หรือชิ้นส่วนมอเตอร์ดูดควันหลวม
                  <br />
                  <br />
                  เรายินดีเข้าไปดูหน้างานโดยไม่คิดค่าใช้จ่าย
                  เพื่อหาสาเหตุของปัญหา และแก้ปัญหาให้ตรงจุด
                  เพื่อความสะดวกและให้ฮูดกลับมาใช้ได้ปกติเร็วที่สุด
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <AnchorLink to="/#contact">
            <Button size="lg" rounded="full" colorScheme="blue" w="xs">
              แจ้งซ่อมทันที
            </Button>
          </AnchorLink>
        </VStack>
      </Container>

      <Container centerContent my="8">
        <VStack spacing="4">
          <Heading
            as="h2"
            textStyle="h3"
            borderBottom="2px"
            borderBottomColor="red.600"
          >
            บริการของเรา
          </Heading>
          <VStack spacing="2">
            <Box
              rounded="md"
              bg="gray.100"
              p="4"
              d="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box mr="4">
                <Image
                  fixed={data.cleanIcon.childImageSharp.fixed}
                  alt="clean icon"
                  objectFit="contain"
                />
              </Box>
              <Box d="flex" flexDir="column" justifyContent="center">
                <Heading as="h3" textStyle="h4" color="blue.700" mb="1">
                  ทำความสะอาดระบบดูดควัน
                </Heading>
                <Text>
                  ล้าง hood ด้วยน้ำยาที่ได้มาตรฐาน กำจัดไขมันและสิ่งสกปรกภายใน
                  hood เพิ่มแรงลมดูด และยืดอายุการใช้งาน
                </Text>
              </Box>
            </Box>
            <Box
              rounded="md"
              bg="gray.100"
              p="4"
              d="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box mr="4">
                <Image
                  fixed={data.fixIcon.childImageSharp.fixed}
                  alt="clean icon"
                  objectFit="contain"
                />
              </Box>
              <Box d="flex" flexDir="column" justifyContent="center">
                <Heading as="h3" textStyle="h4" color="blue.700" mb="1">
                  ซ่อมแซม เปลี่ยนอะไหล่ฮูด
                </Heading>
                <Text>
                  เปลี่ยนมอเตอร์ ซ่อมพัดลม (blower) ซ่อมระบบไฟของฮูดดูดควัน
                  มีปัญหา เปิดไม่ติด เสียงดัง เราแก้ได้หมด
                </Text>
              </Box>
            </Box>
            <Box
              rounded="md"
              bg="gray.100"
              p="4"
              d="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box mr="4">
                <Image
                  fixed={data.consultIcon.childImageSharp.fixed}
                  alt="clean icon"
                  objectFit="contain"
                />
              </Box>
              <Box d="flex" flexDir="column" justifyContent="center">
                <Heading as="h3" textStyle="h4" color="blue.700" mb="1">
                  ให้คำปรึกษา การใช้งาน และดูแลรักษา
                </Heading>
                <Text>
                  ไม่แน่ใจว่าล้างฮูดอย่างไร ควรล้างบ่อย แค่ไหน โทรหาเรา
                  ยินดีให้คำปรึกษาฟรี
                </Text>
              </Box>
            </Box>
          </VStack>
        </VStack>
      </Container>

      <Container my="8" id="contact" centerContent fontSize="lg">
        <Heading
          as="h2"
          textStyle="h3"
          borderBottom="2px"
          borderBottomColor="red.600"
          mb="4"
        >
          ติดต่อเรา
        </Heading>

        <Box d="flex" flexDir="column" alignItems="center" mb="2">
          <Box d="flex" alignItems="center">
            <Text color="blue.500" mr="2">
              <HiPhone />
            </Text>
            <Text color="blue.500" mr="2">
              โทร
            </Text>
          </Box>
          <Text>
            {contactData.phone} ({contactData.name}) 24ชม.
          </Text>
        </Box>
        <Box d="flex" flexDir="column" alignItems="center">
          <Box d="flex" alignItems="center">
            <Text color="blue.500" mr="2">
              <FaFacebook />
            </Text>
            <Text color="blue.500" mr="2">
              Facebook
            </Text>
          </Box>
          <Link
            color="blue.500"
            textDecor="underline"
            isExternal
            href={`https://${contactData.facebook}`}
          >
            {contactData.facebook}
          </Link>
        </Box>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allHeroImg: allImageSharp(
      filter: { fluid: { src: { regex: "/placeholder/" } } }
    ) {
      edges {
        node {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    logo: file(absolutePath: { regex: "/prohood-logo.png/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cleanIcon: file(absolutePath: { regex: "/icon/clean.png/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fixIcon: file(absolutePath: { regex: "/icon/fix.png/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    consultIcon: file(absolutePath: { regex: "/icon/consult.png/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        contact {
          name
          phone
          facebook
        }
      }
    }
  }
`
